<template>
    <div class="login-wrap">
        <el-container>
            <el-main>
   
            <el-row type="flex"  class="row-bg" justify="space-between">
            <el-col :span="3"><div  class="toprow-left" @click='to_login'><el-link style='font-size:15px'>控制台<i class="el-icon-user el-icon--right"></i> </el-link></div></el-col>
         
        </el-row>
        <el-row>
          <el-col :span="24" align="center">
              <div class="title">请先输入校验码</div>
               
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24" align="middle">
          <el-row type="flex" justify="center" >
                       <el-col :span="3">
                       <el-input placeholder="sidentify" v-model="verify" @keyup.enter.native="submitVerify()"/>
                      </el-col>
                    
                   
                    <el-col :span="1">
                        <el-button type="info" @click="submitVerify()">确认</el-button>
                    </el-col>
                    </el-row>
                    <el-row type="flex" justify="center">
                        <el-col :span="4">
                        <div class="code" @click="refreshCode">
                        <Sidentify :identifyCode="identifyCode"></Sidentify>
                        </div>
                         </el-col>
                        </el-row>                           
            <div v-show='showewm'>
                     <wxlogin :theme="'black'"
                   :appid="appid"
                   :scope="'snsapi_login'"
                   :redirect_uri='encodeURIComponent("http://scm.thsen.top/bindopenid")'
                   
                   >
                   </wxlogin>                    
        </div>

             
          </el-col>
        </el-row>
           </el-main>
         <el-footer >
            <div>
             Copyright @ 2020-2021 福州通和信计算机有限公司
         </div>
             <div>网站备案号：<el-link href="https://beian.miit.gov.cn/" >闽ICP备18022481号-2</el-link></div></el-footer>
       </el-container>   
    </div>
    
</template>

<script>
import  md5 from '../components/common/js/allSchool.js'
    import Sidentify from '../components/common/identify'  //**引入验证码组件**
    import store  from '../store'
    import axios from 'axios'
    import wxlogin from 'vue-wxlogin'
    import {PostobjKeySort,StrToDate}  from '../components/common/js/printHelp.js'
   
    export default {
        components: {
            Sidentify,
            wxlogin

        },
        data: function() {
            return {
                reip:Gold.baseURLNoApi,                             
                showiput:false,
                showewm:false,
                appid:Gold.appid,
                 ip: ''  ,
                identifyCode: '',
                identifyCodes:'1234567890',

                verify:''
                
                
            };
        },
        mounted(){
            this.getIP();
            this.refreshCode();            
            },
        created(){     
        },
        methods: {
            to_login(){

                this.$router.push("./login")
                },
           
          
    
       getIP() {
            let that=this
            md5.cIp(that)
         //  that.md5.commonGetIp(that);
 
             },

            refreshCode(){
                console.log(md5.makeCode(4));
                this.identifyCode="";
                this.identifyCode=md5.makeCode(4);

            },
             submitVerify() {
                const that = this
                if(this.identifyCode==this.verify){
                    this.showewm=true;
               }else{
                   alert("验证码不对!")
                   this.refreshCode()
               }
            }
            },
    };
</script>

<style scoped>
    .login-wrap {
        position: relative;
        width: 100%;
        height: 100%;
        background-image: url(../assets/img/new2.jpg);
        background-size: 100%
    }
    .ms-title {
        width: 100%;
        line-height: 50px;
        text-align: center;
        font-size: 24px;
        color:rgb(243, 246, 246);

        padding-top: 200px;
    }
    .ms-login {
        position: absolute;
        left: 50%;
        top: 30%;
        width: 350px;
        margin: -90px 0 0 -175px;
        border-radius: 5px;
        background: rgba(255, 255, 255, 0.3);
        overflow: hidden;
        padding-top: 10px;
    }
    .ms-content {
        padding: 30px 30px;
    }
    .login-btn {
        text-align: center;
    }
    .login-btn button {
        width: 100%;
        height: 36px;
        margin-bottom: 10px;
    }
    .login-tips {
        font-size: 12px;
        line-height: 30px;
        color: #fff;
    }
    .title{

        text-align: center;
        font-size: 40px;
        color:rgb(1, 24, 24);
        margin: 100px 0px 0px 0px ;

    }
    .form-body{
        width: 350px;
        padding: 70px;

        background-image: url(../assets/img/form_back.png);

        background-size:100%;
        padding-top:60px;
        padding-bottom: 20px;
        height:280px;
    }
    .code{
        width: 100%;
        text-align: left;
    }
    .toprow-left{
        padding-left: 30px;
        padding-top:30px;       
        font-size:30px
    }
     .toprow-right{
        text-align: right;
        padding-top:30px; 
        padding-right: 30px;      
        font-size:30px
    }
    .el-footer {
    background-color: #B3C0D1;
    color: #333;
    text-align: center; 
    
  }
  .el-container{
    height: 100%;
    }
</style>